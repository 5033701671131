// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-post-template-js": () => import("./../../../src/components/PostTemplate.js" /* webpackChunkName: "component---src-components-post-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-en-us-js": () => import("./../../../src/pages/blog/en-us.js" /* webpackChunkName: "component---src-pages-blog-en-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-pt-br-js": () => import("./../../../src/pages/blog/pt-br.js" /* webpackChunkName: "component---src-pages-blog-pt-br-js" */),
  "component---src-pages-en-us-js": () => import("./../../../src/pages/en-us.js" /* webpackChunkName: "component---src-pages-en-us-js" */),
  "component---src-pages-faq-en-us-js": () => import("./../../../src/pages/faq/en-us.js" /* webpackChunkName: "component---src-pages-faq-en-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-pt-br-js": () => import("./../../../src/pages/faq/pt-br.js" /* webpackChunkName: "component---src-pages-faq-pt-br-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pt-br-js": () => import("./../../../src/pages/pt-br.js" /* webpackChunkName: "component---src-pages-pt-br-js" */)
}

